import {
	GeneralState,
	GeneralActionTypes,
	LOAD_LOADING_SCREEN,
	LOAD_BACKDROP,
	UPDATE_PICKED_CHAMPIONS,
	UPDATE_SELECTED_ROLES,
	UPDATE_NUMBER_OF_GAMES,
	UPDATE_NUMBER_OF_MASTER_POINTS,
	UPDATE_NUMBER_OF_MASTER_STARTING_POINTS,
	UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS,
	UPDATE_COACH_RANK,
	UPDATE_NUMBER_OF_HOURS,
	UPDATE_COACH_LINES,
	UPDATE_COACHING_TYPE,
	UPDATE_SERVICE_TYPE,
	SET_COOKIE_VISIBILITY,
	SET_POPUP,
} from '../types/generalTypes';

export const generalReducer = (state = initialState, action: GeneralActionTypes): GeneralState => {
	switch (action.type) {
		case LOAD_LOADING_SCREEN:
			return { ...state, isLoading: action.payload };
		case LOAD_BACKDROP: {
			return { ...state, isBackdropActive: action.payload };
		}
		case UPDATE_PICKED_CHAMPIONS: {
			return { ...state, pickedChampions: action.payload };
		}
		case UPDATE_SELECTED_ROLES: {
			return { ...state, selectedRoles: action.payload };
		}
		case UPDATE_NUMBER_OF_GAMES: {
			return { ...state, numberOfGames: action.payload };
		}
		case UPDATE_NUMBER_OF_MASTER_POINTS: {
			return { ...state, numberOfMasterPoints: action.payload };
		}
		case UPDATE_NUMBER_OF_MASTER_STARTING_POINTS: {
			let desiredPoints = state.numberOfMasterPoints.desiredPoints;
			if (action.payload > desiredPoints) {
				desiredPoints = action.payload + 15;
			}
			return {
				...state,
				numberOfMasterPoints: {
					desiredPoints: desiredPoints,
					startingPoints: action.payload,
				},
			};
		}
		case UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS: {
			let startingPoints = state.numberOfMasterPoints.startingPoints;
			if (action.payload < startingPoints) {
				startingPoints = action.payload - 15;
				if (startingPoints < 0) {
					startingPoints = 0;
				}
			}
			return {
				...state,
				numberOfMasterPoints: {
					startingPoints: startingPoints,
					desiredPoints: action.payload,
				},
			};
		}
		case UPDATE_NUMBER_OF_HOURS: {
			return { ...state, numberOfHours: action.payload };
		}
		case UPDATE_COACH_RANK: {
			return { ...state, coachRank: action.payload };
		}
		case UPDATE_COACH_LINES: {
			return { ...state, coachLines: action.payload };
		}
		case UPDATE_COACHING_TYPE: {
			return { ...state, coachingType: action.payload };
		}
		case UPDATE_SERVICE_TYPE: {
			return { ...state, serviceType: action.payload };
		}
		case SET_COOKIE_VISIBILITY: {
			return {...state, isCookiesVisible: action.payload};
		}
		case SET_POPUP: {
			return { ...state, popup: action.payload };
		}
		default:
			return state;
	}
};

const initialState: GeneralState = {
	isLoading: true,
	isBackdropActive: true,
	pickedChampions: [],
	selectedRoles: [],
	numberOfGames: 1,
	numberOfMasterPoints: { startingPoints: 0, desiredPoints: 20 },
	numberOfHours: 2,
	coachRank: 'master',
	coachLines: [],
	coachingType: 'regular',
	serviceType: 'solo',
	isCookiesVisible: true,
	popup: undefined,
};
