import {
	BaseNameMultiplier,
	BaseRangeMultiplier,
	CoachingPricing,
	ExtrasMultipliers,
	PayPerGameResponseBody,
	OrderRank,
	TiersPricing,
	PayPerGamePricing,
	ServerMultiplier,
} from 'types/general';
import { APICoupon } from 'types/order';

export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
type SET_ORDER_TYPE = typeof SET_ORDER_TYPE;

export const SET_RANK_FROM = 'SET_RANK_FROM';
type SET_RANK_FROM = typeof SET_RANK_FROM;

export const SET_RANK_TO = 'SET_RANK_TO';
type SET_RANK_TO = typeof SET_RANK_TO;

export const SET_STARTING_LP = 'SET_STARTING_LP';
type SET_STARTING_LP = typeof SET_STARTING_LP;

export const SET_LP_GAIN = 'SET_LP_GAIN';
type SET_LP_GAIN = typeof SET_LP_GAIN;

export const SET_QUEUE_TYPE = 'SET_QUEUE_TYPE';
type SET_QUEUE_TYPE = typeof SET_QUEUE_TYPE;

export const SET_SERVER_TYPE = 'SET_SERVER_TYPE';
type SET_SERVER_TYPE = typeof SET_SERVER_TYPE;

export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';
type SET_TOTAL_PRICE = typeof SET_TOTAL_PRICE;

export const SET_IS_PRIORITY = 'SET_IS_PRIORITY';
type SET_IS_PRIORITY = typeof SET_IS_PRIORITY;

export const SET_EXTRAS = 'SET_EXTRAS';
type SET_EXTRAS = typeof SET_EXTRAS;

export const SET_COACHING_PARAMS = 'SET_COACHING_PARAMS';
type SET_COACHING_PARAMS = typeof SET_COACHING_PARAMS;

export const SET_TIERS_PRICING = 'SET_TIERS_PRICING';
type SET_TIERS_PRICING = typeof SET_TIERS_PRICING;

export const SET_NET_WINS_PRICING = 'SET_NET_WINS_PRICING';
type SET_NET_WINS_PRICING = typeof SET_NET_WINS_PRICING;

export const SET_PLACEMENT_PRICING = 'SET_PLACEMENT_PRICING';
type SET_PLACEMENT_PRICING = typeof SET_PLACEMENT_PRICING;

export const SET_COUPON = 'SET_COUPON';
type SET_COUPON = typeof SET_COUPON;

export const SET_BASE_PRICE = 'SET_BASE_PRICE';
type SET_BASE_PRICE = typeof SET_BASE_PRICE;
interface SetOrderType {
	type: SET_ORDER_TYPE;
	payload: string | undefined;
}

interface SetRankFrom {
	type: SET_RANK_FROM;
	payload: OrderRank | undefined;
}

interface SetRankTo {
	type: SET_RANK_TO;
	payload: OrderRank | undefined;
}

interface SetStartingLP {
	type: SET_STARTING_LP;
	payload: BaseRangeMultiplier | undefined;
}

interface SetLpGain {
	type: SET_LP_GAIN;
	payload: BaseRangeMultiplier | undefined;
}

interface SetQueueType {
	type: SET_QUEUE_TYPE;
	payload: BaseNameMultiplier | undefined;
}

interface SetServerType {
	type: SET_SERVER_TYPE;
	payload: BaseNameMultiplier | undefined;
}

interface SetTotalPrice {
	type: SET_BASE_PRICE;
	payload: number | undefined;
}

interface SetBasePrice {
	type: SET_TOTAL_PRICE;
	payload: number | undefined;
}

interface SetIsPriority {
	type: SET_IS_PRIORITY;
	payload: boolean | undefined;
}

interface SetExtras {
	type: SET_EXTRAS;
	payload: ExtrasMultipliers | undefined;
}

interface SetCoachingParams {
	type: SET_COACHING_PARAMS;
	payload: CoachingPricing | undefined;
}

interface SetTiersPricing {
	type: SET_TIERS_PRICING;
	payload: TiersPricing | undefined;
}

interface SetNetWinsPricing {
	type: SET_NET_WINS_PRICING;
	payload: PayPerGamePricing;
}

interface SetPlacementPricing {
	type: SET_PLACEMENT_PRICING;
	payload: PayPerGamePricing;
}

interface SetCoupon {
	type: SET_COUPON;
	payload: APICoupon;
}

export interface PaymentState {
	tiersPricing?: TiersPricing;
	netWinsPricing?: PayPerGamePricing;
	placementPricing?: PayPerGamePricing;
	orderType?: string;
	priceValue?: number;
	rankFrom?: OrderRank;
	rankTo?: OrderRank;
	startingLP?: BaseRangeMultiplier;
	lpGain?: BaseRangeMultiplier;
	queueType?: BaseNameMultiplier;
	server?: ServerMultiplier;
	totalPrice?: number;
	isPriority?: boolean;
	extras?: ExtrasMultipliers;
	coachingParams?: CoachingPricing;
	coupon?: APICoupon;
	basePrice?: number;
}

export type PaymentActionTypes =
	| SetOrderType
	| SetRankFrom
	| SetRankTo
	| SetStartingLP
	| SetLpGain
	| SetQueueType
	| SetServerType
	| SetTotalPrice
	| SetIsPriority
	| SetExtras
	| SetCoachingParams
	| SetTiersPricing
	| SetNetWinsPricing
	| SetPlacementPricing
	| SetCoupon
	| SetBasePrice;
