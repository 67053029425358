import { combineReducers } from 'redux';
import { generalReducer } from './generalReducer';
import { paymentReducer } from './paymentReducer';
import { authReducer } from './authReducer';

const rootReducer = combineReducers({
	general: generalReducer,
	payment: paymentReducer,
	auth: authReducer,
});

export default rootReducer;
