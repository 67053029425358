import { LoginResponseBody } from 'types/auth';

export const AUTH_SUCESS = 'AUTH_SUCESS';
type AUTH_SUCESS = typeof AUTH_SUCESS;

interface SetAuthData {
	type: AUTH_SUCESS;
	payload: LoginResponseBody | undefined;
}

export type AuthActionTypes = SetAuthData;

export interface AuthState extends LoginResponseBody {}
