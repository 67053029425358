import {
	PaymentActionTypes,
	PaymentState,
	SET_BASE_PRICE,
	SET_COACHING_PARAMS,
	SET_COUPON,
	SET_EXTRAS,
	SET_IS_PRIORITY,
	SET_LP_GAIN,
	SET_NET_WINS_PRICING,
	SET_ORDER_TYPE,
	SET_PLACEMENT_PRICING,
	SET_QUEUE_TYPE,
	SET_RANK_FROM,
	SET_RANK_TO,
	SET_SERVER_TYPE,
	SET_STARTING_LP,
	SET_TIERS_PRICING,
	SET_TOTAL_PRICE,
} from '../types/paymentTypes';

export const paymentReducer = (state = initialState, action: PaymentActionTypes): PaymentState => {
	switch (action.type) {
		case SET_ORDER_TYPE:
			return { ...state, orderType: action.payload };
		case SET_RANK_FROM: {
			return { ...state, rankFrom: action.payload };
		}
		case SET_RANK_TO: {
			return { ...state, rankTo: action.payload };
		}
		case SET_STARTING_LP: {
			return { ...state, startingLP: action.payload };
		}
		case SET_LP_GAIN: {
			return { ...state, lpGain: action.payload };
		}
		case SET_QUEUE_TYPE: {
			return { ...state, queueType: action.payload };
		}
		case SET_SERVER_TYPE: {
			return { ...state, server: action.payload };
		}
		case SET_TOTAL_PRICE: {
			return { ...state, totalPrice: action.payload };
		}
		case SET_IS_PRIORITY: {
			return { ...state, isPriority: action.payload };
		}
		case SET_EXTRAS: {
			return { ...state, extras: action.payload };
		}
		case SET_COACHING_PARAMS: {
			return { ...state, coachingParams: action.payload };
		}
		case SET_TIERS_PRICING: {
			return { ...state, tiersPricing: action.payload };
		}
		case SET_NET_WINS_PRICING: {
			return { ...state, netWinsPricing: action.payload };
		}
		case SET_PLACEMENT_PRICING: {
			return { ...state, placementPricing: action.payload };
		}
		case SET_COUPON: {
			return {...state, coupon: action.payload};
		}
		case SET_BASE_PRICE: {
			return { ...state, basePrice: action.payload }
		}
		default:
			return state;
	}
};

const initialState: PaymentState = {
	orderType: undefined,
	priceValue: undefined,
	rankFrom: undefined,
	rankTo: undefined,
	startingLP: undefined,
	lpGain: undefined,
	server: undefined,
	totalPrice: undefined,
	isPriority: false,
	extras: undefined,
	coachingParams: undefined,
	tiersPricing: undefined,
	netWinsPricing: undefined,
	placementPricing: undefined,
	coupon: undefined,
	basePrice: undefined,
};
