import { AuthActionTypes, AuthState, AUTH_SUCESS } from '../types/authTypes';

export const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
	switch (action.type) {
		case AUTH_SUCESS: {
			return { ...state, ...action.payload };
		}
		default:
			return state;
	}
};

const initialState: AuthState = {
	id: undefined,
	username: undefined,
	role: undefined,
	accessToken: undefined,
	refreshToken: undefined,
};
