import { Popup } from "types/general";

export const LOAD_LOADING_SCREEN = 'LOAD_LOADING_SCREEN';
type LOAD_LOADING_SCREEN = typeof LOAD_LOADING_SCREEN;

export const LOAD_BACKDROP = 'LOAD_BACKDROP';
type LOAD_BACKDROP = typeof LOAD_BACKDROP;

export const UPDATE_PICKED_CHAMPIONS = 'UPDATE_PICKED_CHAMPIONS';
type UPDATE_PICKED_CHAMPIONS = typeof UPDATE_PICKED_CHAMPIONS;

export const UPDATE_SELECTED_ROLES = 'UPDATE_SELECTED_ROLES';
type UPDATE_SELECTED_ROLES = typeof UPDATE_SELECTED_ROLES;

export const UPDATE_NUMBER_OF_GAMES = 'UPDATE_NUMBER_OF_GAMES';
type UPDATE_NUMBER_OF_GAMES = typeof UPDATE_NUMBER_OF_GAMES;

export const UPDATE_NUMBER_OF_MASTER_POINTS = 'UPDATE_NUMBER_OF_MASTER_POINTS';
type UPDATE_NUMBER_OF_MASTER_POINTS = typeof UPDATE_NUMBER_OF_MASTER_POINTS;

export const UPDATE_NUMBER_OF_MASTER_STARTING_POINTS = 'UPDATE_NUMBER_OF_MASTER_STARTING_POINTS';
type UPDATE_NUMBER_OF_MASTER_STARTING_POINTS = typeof UPDATE_NUMBER_OF_MASTER_STARTING_POINTS;

export const UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS = 'UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS';
type UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS = typeof UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS;

export const UPDATE_NUMBER_OF_HOURS = 'UPDATE_NUMBER_OF_HOURS';
type UPDATE_NUMBER_OF_HOURS = typeof UPDATE_NUMBER_OF_HOURS;

export const UPDATE_COACH_RANK = 'UPDATE_COACH_RANK';
type UPDATE_COACH_RANK = typeof UPDATE_COACH_RANK;

export const UPDATE_COACH_LINES = 'UPDATE_COACH_LINES';
type UPDATE_COACH_LINES = typeof UPDATE_COACH_LINES;

export const UPDATE_COACHING_TYPE = 'UPDATE_COACHING_TYPE';
type UPDATE_COACHING_TYPE = typeof UPDATE_COACHING_TYPE;

export const UPDATE_SERVICE_TYPE = 'UPDATE_SERVICE_TYPE';
type UPDATE_SERVICE_TYPE = typeof UPDATE_SERVICE_TYPE;

export const SET_COOKIE_VISIBILITY = 'SET_COOKIE_VISIBILITY';
type SET_COOKIE_VISIBILITY = typeof SET_COOKIE_VISIBILITY;

export const SET_POPUP = 'SET_POPUP';
type SET_POPUP = typeof SET_POPUP;

interface LoadLoadingScreen {
	type: LOAD_LOADING_SCREEN;
	payload: boolean;
}

interface LoadBackdrop {
	type: LOAD_BACKDROP;
	payload: boolean;
}

interface UpdatePickedChampions {
	type: UPDATE_PICKED_CHAMPIONS;
	payload?: string[] | undefined;
}

interface UpdateSelectedRoles {
	type: UPDATE_SELECTED_ROLES;
	payload?: string[] | undefined;
}

interface UpdateNrOfGames {
	type: UPDATE_NUMBER_OF_GAMES;
	payload: number;
}

export interface MasterPoints {
	startingPoints: number;
	desiredPoints: number;
}

interface UpdateNrOfMasterPoints {
	type: UPDATE_NUMBER_OF_MASTER_POINTS;
	payload: MasterPoints;
}

interface UpdateNrOfMasterStartingPoints {
	type: UPDATE_NUMBER_OF_MASTER_STARTING_POINTS;
	payload: number;
}

interface UpdateNrOfMasterDesiredPoints {
	type: UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS;
	payload: number;
}

interface UpdateNumberOfHours {
	type: UPDATE_NUMBER_OF_HOURS;
	payload: number;
}

interface UpdateCoachRank {
	type: UPDATE_COACH_RANK;
	payload: string;
}

interface UpdateCoachLines {
	type: UPDATE_COACH_LINES;
	payload: string[];
}

interface UpdateCoachingType {
	type: UPDATE_COACHING_TYPE;
	payload: string;
}

interface UpdateServiceType {
	type: UPDATE_SERVICE_TYPE;
	payload: string;
}

interface SetCookieVisibility {
	type: SET_COOKIE_VISIBILITY;
	payload: boolean;
}

interface SetPopup {
	type: SET_POPUP;
	payload: Popup | undefined;
}

export interface GeneralState {
	isLoading: boolean;
	isBackdropActive: boolean;
	pickedChampions?: string[];
	selectedRoles?: string[];
	numberOfGames: number;
	numberOfMasterPoints: MasterPoints;
	numberOfHours: number;
	coachRank: string;
	coachLines: string[];
	coachingType: string;
	serviceType: string;
	isCookiesVisible: boolean;
	popup?: Popup;
}

export type GeneralActionTypes =
	| LoadLoadingScreen
	| LoadBackdrop
	| UpdatePickedChampions
	| UpdateSelectedRoles
	| UpdateNrOfGames
	| UpdateNrOfMasterPoints
	| UpdateNrOfMasterStartingPoints
	| UpdateNrOfMasterDesiredPoints
	| UpdateNumberOfHours
	| UpdateCoachRank
	| UpdateCoachLines
	| UpdateCoachingType
	| UpdateServiceType
	| SetCookieVisibility
	| SetPopup;
